<template>
  <div>
    <h2 class="title form__title">Подать заявку на кредит</h2>
    <div class="app__form">
      <div class="container">
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="Введите ваши Ф.И.О"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.fio"
              type="text"
              placeholder="Ф.И.О"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Введите ваша номер телефона"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.phone_number"
              placeholder="Телефон"
              required
            ></b-form-input>
          </b-form-group>

          <b-button class="app__form-share-btn">Поделиться моим номером</b-button>

          <b-button class="app__form-submit-btn" type="submit">Отправить</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppForm",
  data() {
    return {
      form: {
        fio: '',
        phone_number: ''
      }
    }
  },
  methods: {
    onSubmit() {
      const formData = new FormData
      formData.append('full_name', this.form.fio)
      formData.append('phone', this.form.phone_number)
      this.$http.post(process.env.VUE_APP_URL + 'api/sendForm', formData)
        .then(res => {
          console.log(res)
          this.$router.push('/form-success')
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .app__form {
  background-color: #FACC15;
  padding: 16px 0;

  & label {
    display: block;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 8px;
  }

  & input {
    padding: 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.1px;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #969696;
    border-radius: 4px;
    height: 56px;

    &:focus {
      box-shadow: none;
      border: 1px solid #1C1C1C;
    }
  }

  button {
    width: 100%;
    display: block;
    margin-top: 16px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.1px;
  }

  &-share-btn {
    border: 1px solid #1C1C1C;
    background-color: transparent;
    color: #1C1C1C;
    padding: 10px;
  }

  &-submit-btn {
    background-color: #1c1c1c;
    color: #fff;
    padding: 10px;
    display: block;
    margin-top: 1rem;

    &:active {
      background-color: #1c1c1c !important;
    }

    &:hover {
      background-color: #1c1c1c !important;
    }

    &:focus-visible {
      background-color: #1c1c1c !important;
      box-shadow: none;
      border: 1px solid #1c1c1c;
    }
  }
}

</style>
