<template>
  <div>
    <h2 class="title calculator__title">Калькулятор кредита</h2>
    <div class="calculator">
      <div class="container">
        <b-form @submit.prevent="onSubmit">

          <!--     CARS     -->
          <div>
            <label for="cars">Выберите модель автомобиля</label>
            <v-select
              id="cars"
              :options="cars"
              v-model="selectedCar"
              label="name"
              placeholder="Модель"
              @input="getPowerFromSelectedCar"
              @change="clearPower"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!selectedCar"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>

          <!--     YEAR     -->
          <div>
            <label for="cars">Выберите год модели автомобиля</label>
            <v-select
              id="cars"
              :options="years"
              v-model="selectedYear"
              label="name"
              placeholder="Год"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!selectedYear"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>

          <!--     TERM     -->
          <div>
            <label for="cars">Выберите желаемый срок кредитования</label>
            <v-select
              id="cars"
              :options="terms"
              v-model="selectedTerm"
              label="name"
              placeholder="Срок"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!selectedTerm"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
            <span class="calculator__span">указывается в годах</span>
          </div>

          <!--     POWER     -->
          <div>
            <label for="cars">Выберите мощность автомобиля</label>
            <v-select
              id="cars"
              :options="power"
              v-model="selectedPower"
              label="name"
              placeholder="Мощность"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!selectedPower"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>

          <!--     PRICE     -->
          <b-form-group
            id="input-group-1"
            label="Укажите стоимость автомобиля"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="price"
              type="text"
              min="0"
              :max="maxPriceLimit"
              placeholder="Стоимость"
              required
            ></b-form-input>
            <span v-if="selectedCar" class="calculator__span">максимальная стоимость для данной модели {{
                selectedCar['max_price']
              }} сум</span>
          </b-form-group>

          <!--   PROVE OF PROFIT   -->
          <div class="calculator__checkboxes">
            <b-form-group label="Справка о доходах" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                class="d-flex align-items-center status-sort"
                v-model="proveOfProfit"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  :value="true"
                >
                  Да
                </b-form-checkbox>

                <b-form-checkbox
                  id="checkbox-2"
                  :value="false"
                  name="checkbox-2"
                >
                  Нет
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>


          <b-button
            class="calculator__submit-btn"
            type="submit"
          >
            Рассчитать
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>

import {tg} from "@/main";

export default {
  name: "AppCalculator",
  data() {
    return {
      cars: [],
      selectedCar: null,
      years: [],
      selectedYear: null,
      terms: [
        {
          name: '3',
          id: 1
        },
        {
          name: '4',
          id: 2
        },
        {
          name: '5',
          id: 3
        },
      ],
      selectedTerm: null,
      power: [],
      selectedPower: null,
      price: null,
      proveOfProfit: true,
      calculationDetails: {}
    }
  },

  computed:{
    maxPriceLimit(){
      if(this.selectedCar){
        return this.selectedCar['max_price']
      }

      return undefined
    }
  },

  async mounted() {
    await this.getCars()
    await this.getCarYears()
    // await this.getCarPower()
  },

  methods: {

    async getCars() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/cars')
        .then(res => {
          this.cars = res.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getCarYears() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/years')
        .then(res => {
          this.years = res.data.map(item => {
            return {
              name: item.year,
              id: item.id
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
    },

    getPowerFromSelectedCar() {
      this.power = this.selectedCar.horsepower.map(item => {
        return {
          name: item.value
        }
      })
      this.clearPower()
    },

    clearPower() {
      return this.selectedPower = null
    },

    onSubmit() {
      const formData = new FormData()
      formData.append('car_id', this.selectedCar.id)
      formData.append('year_id', this.selectedYear.id)
      formData.append('horsepower', this.selectedPower.name)
      formData.append('credit_year', this.selectedTerm.name)
      formData.append('price', this.price)
      formData.append('income_statement', this.proveOfProfit)
      this.$http.post(process.env.VUE_APP_URL + 'api/calculate', formData)
        .then(res => {
          this.$router.push({name: 'calculation', params: {data: res.data}})
          window.scrollTo(0,0)
        })
        .catch(error => {
          console.error(error)
        })

    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .vs__clear {
  margin: 0 1rem 4px;
}


.calculator {
  background-color: #FACC15;
  padding: 0 0 1rem;

  ::v-deep & ul.vs__dropdown-menu {

    & li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 56px !important;

      &:hover {
        background-color: #FEF5D0;
        color: #000;
      }
    }
  }

  &__span {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.25px;
    color: #000000;
    display: block;
    text-align: left;
    margin-top: 8px;
  }

  ::v-deep & label {
    display: block;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    padding: 1rem 0 0.5rem;
    margin-bottom: 0;
  }

  ::v-deep & input {
    padding: 8px 1rem 12px;

    &::placeholder {
      color: #969696;
    }
  }

  ::v-deep & .vs__actions {
    padding: 8px 1rem 12px;
  }

  ::v-deep & .vs__dropdown-toggle {
    padding: 0;
  }

  ::v-deep &__checkboxes {

    & legend {
      margin: 1rem 0;
      padding: 0;
      display: block;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #000000;
    }

    & .custom-checkbox {
      display: flex;
      align-items: center;
      margin-right: 3.5rem;


      & input {
        width: 20px;
        height: 20px;
        border: 2px solid #000 !important;
      }
    }

    & .custom-control-label::after {
      width: 20px;
      height: 20px;
    }

    & .custom-control-label::before {
      width: 20px;
      height: 20px;
      border: 2px solid #000;
      border-radius: 4px;
    }

    & label {
      padding: 0 0 0 .75rem;
    }
  }

  &__submit-btn {
    display: block;
    width: 100%;
    background: #1C1C1C;
    border-radius: 4px;
    border: none;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.1px;
    color: #FFFFFF;

    &:active {
      background-color: #1C1C1C !important;
      border: none !important;
    }

    &:focus {
      background-color: #1C1C1C !important;
      box-shadow: none !important;
      border: none !important;
    }

    &:hover {
      background-color: #1C1C1C !important;
      border: none !important;
    }

    &:focus-visible {
      outline: none;
    }
  }
}


.form-control {
  height: auto;
}

::v-deep .status-sort {

  .custom-control-input:checked ~ .custom-control-label::after {
    top: 0.3rem;
  }

  //.custom-control-input:focus {
  //  box-shadow: none !important;
  //}

  .custom-checkbox {
    color: #000;

    .custom-control-label {
      &::before {
        border: 2px solid #000;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #000;
    }
  }
}


</style>
