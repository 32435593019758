<template>
  <div class="footer d-flex flex-column align-items-center">
    <a
        v-if="footerDetails.phone"
        v-for="(phone, index) in footerDetails.phone"
        :key="index"
        class="footer__link"
       :href="'tel:' + phone.value"
        target="_blank"
    >
      {{ phone.value }}
    </a>
    <p class="footer__text">{{ footerDetails.title }}</p>
  </div>
</template>

<script>

export default {
  name: "AppFooterLayout",

  props: {
    footerDetails: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    phoneLink() {
      let a = document.getElementById('phoneNumber');
      a.href = "tel:+998998008080";
      a.click()
    }
  }

}
</script>

<style lang="scss" scoped>

.footer {
  padding: 32px 0;

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.1px;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 8px;
    color: #168ACD;
    //color: var(--tg-theme-link-color);
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: var(--tg-theme-text-color);
    //color: var(--tg-theme-text-color);
  }
}

</style>
