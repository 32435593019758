<template>
  <div>
    <h2 class="title">{{ profitabilityTitle }}</h2>
    <div class="profitability">
      <div class="container">
        <p
          v-for="(item, index) in profitabilityItems"
          :key="index"
        >
          {{ item['answer'] }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AppAboutProfitable",
  data() {
    return {
      profitabilityTitle: '',
      profitabilityItems: []
    }
  },

  async created() {
    await this.getProfitDetails()
  },

  methods: {
    async getProfitDetails() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/questions/2')
        .then(res => {
          this.profitabilityTitle = res.data['question']
          this.profitabilityItems = res.data['answers']
          console.log(res.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.profitability {
  background-color: #FACC15;
  padding: 16px 0;

  & p {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
  }

}

</style>
