<template>
  <div class="form__success">
    <div class="container">
      <img :src="successFormInfo.image" alt="">
      <h3>{{ successFormInfo.title }}</h3>
      <p>{{ successFormInfo.description }}</p>
      <router-link to="/" class="w-btn">В начало</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFormSuccess",
  data() {
    return {
      successFormInfo: {
        image: require('@/assets/img/tick-icon.svg'),
        title: 'Ваша заявка принята!',
        description: 'В ближайшее время с вами свяжется наш ответсвенный сотрудник'
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.form__success {
  background-color: #FACC15;
  padding: 3rem 0 1rem;

  & img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-bottom: 8px;
  }

  & h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
    padding: 0 .5rem;
    margin: 0;
  }

  & a {
    display: block;
    text-decoration: none;
    margin-top: 3rem;
    background-color: #1C1C1C;
    color: #fff;

    &:active {

    }
  }
}

</style>
