<template>
  <div>
    <h2 class="title calculation__title">Ваш расчет готов!</h2>
    <div class="calculation">
      <div class="container">
        <div
          v-for="(item,index) in calculationDetails"
          :key="index"
          class="calculation__block"
        >
          <h5
            v-if="item.className === 'text-bold'"
            :class="item.className"
          >
            {{ item.text }}
          </h5>

          <div
            v-else
            :class="item.className"
          >
            <span>{{ item.text }}</span>
            <div
              v-for="(li, index) in item.description"
              :key="index"
            >
              <span :class="li.className">{{ li.text }}</span>
            </div>
          </div>
        </div>

        <router-link
          to="/"
          class="calculation__btn"
        >
          В начало
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCalculation",
  data() {
    return {
      creditYear: null,
      loanAmount: null,
      model: '',
      monthlyPayment: null,
      overpaymentOneMonthPercent: null,
      overpaymentOneMonthSum: null,
      overpaymentOneYearSum: null,
      price: null,
      releaseYear: null,
      servicesValuationOrganization: null,
      totalInitialCosts: null,
      totalInitialPayment: null,
      totalInsurance: null,
      totalNotary: null,
      totalTrafficPolice: null,

      calculationDetails: [
        {
          text: 'Модель автомобиля:',
          className: 'text-bold'
        },
        {
          text: this.model,
          className: 'text-regular'
        },
        {
          text: 'Год выпуска:',
          className: 'text-bold'
        },
        {
          text: this.releaseYear,
          className: 'text-regular'
        },
        {
          text: 'Стоимость:',
          className: 'text-bold'
        },
        {
          text: this.price,
          className: 'text-regular'
        },
        {
          text: 'Первоначальные расходы:',
          className: 'text-bold'
        },
        {
          text: `${this.totalInitialCosts} сум, в том числе:`,
          className: 'text-regular',
          description: [
            {
              text: `предоплата: ${this.totalInitialPayment} сум;`,
              className: 'text-regular text__with-dots pt-2'
            },
            {
              text: `страхование: ${this.totalInsurance} сум;`,
              className: 'text-regular text__with-dots'
            },
            {
              text: `оценка: ${this.servicesValuationOrganization} сум;`,
              className: 'text-regular text__with-dots'
            },
            {
              text: `нотариус: ${this.totalNotary} сум;`,
              className: 'text-regular text__with-dots'
            },
            {
              text: `ГАИ: ${this.totalTrafficPolice} сум.`,
              className: 'text-regular text__with-dots'
            },
          ]
        },
        {
          text: 'Сумма кредита:',
          className: 'text-bold'
        },
        {
          text: `${this.loanAmount} сум`,
          className: 'text-regular'
        },
        {
          text: 'Срок кредита:',
          className: 'text-bold'
        },
        {
          text: `${this.creditYear} лет`,
          className: 'text-regular'
        },
        {
          text: 'Переплата за 1 год:',
          className: 'text-bold'
        },
        {
          text: `${this.overpaymentOneYearSum} сум`,
          className: 'text-regular'
        },
        {
          text: 'Переплата за 1 месяц:',
          className: 'text-bold'
        },
        {
          text: `${this.overpaymentOneMonthSum} сум`,
          className: 'text-regular'
        },
        {
          text: 'Переплата за 1 месяц в процентах:',
          className: 'text-bold'
        },
        {
          text: `${this.overpaymentOneMonthPercent}%`,
          className: 'text-regular'
        },
        {
          text: 'Ежемесячный платеж:',
          className: 'text-bold'
        },
        {
          text: `${this.monthlyPayment} сум`,
          className: 'text-regular'
        },
      ]
    }
  },

  beforeCreate() {
    this.creditYear = this.$route.params.data['credit_year']
    this.loanAmount = this.$route.params.data['loan_amount']
    this.model = this.$route.params.data['model']
    this.monthlyPayment = this.$route.params.data['monthly_payment']
    this.overpaymentOneMonthPercent = this.$route.params.data['overpayment_one_month_percent']
    this.overpaymentOneMonthSum = this.$route.params.data['overpayment_one_month_sum']
    this.overpaymentOneYearSum = this.$route.params.data['overpayment_one_year_sum']
    this.price = this.$route.params.data['price']
    this.releaseYear = this.$route.params.data['release_year']
    this.servicesValuationOrganization = this.$route.params.data['services_valuation_organization']
    this.totalInitialCosts = this.$route.params.data['total_initial_costs']
    this.totalInitialPayment = this.$route.params.data['total_initial_payment']
    this.totalInsurance = this.$route.params.data['total_insurance']
    this.totalNotary = this.$route.params.data['total_notary']
    this.totalTrafficPolice = this.$route.params.data['total_traffic_police']
  },

}
</script>

<style lang="scss" scoped>

.calculation {
  background-color: #FACC15;
  padding: 1rem 0;

  & .text-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: left;
    margin-bottom: 4px;
  }

  & .text-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: left;
    margin-bottom: 1rem;
  }

  & .text__with-dots {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &::before {
      display: flex;
      flex-direction: row;
      content: '';
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
      margin: 4px .5rem 0 0;
    }
  }

  .calculation__btn {
    width: 100%;
    height: 40px;
    background: #1C1C1C;
    border-radius: 4px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      background-color: #1C1C1C !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

}

</style>
