<template>
  <div id="app">
    <router-view/>
    <AppFooterLayout
        :footer-details="footerDetails"
    />
  </div>
</template>

<script>
import AppFooterLayout from "@/components/AppFooterLayout";
import {tg} from "@/main";

export default {
  components: {AppFooterLayout},

  data() {
    return {
      footerDetails: {
        title: '',
        phone: null,
        phone2: null
      }
    }
  },

  async mounted() {
    await this.getFooterInfo()
    await this.setBgColor()
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.setBackBtn()
      }
    }
  },

  methods: {
    setBgColor() {
      if (tg.colorScheme === 'dark') {
        document.getElementById('app').style.backgroundColor = 'var(--tg-theme-bg-color)'
        tg.headerColor = 'var(--tg-theme-bg-color)'
      } else {
        document.getElementById('app').style.backgroundColor = 'var(--tg-theme-bg-color)'
        tg.headerColor = 'var(--tg-theme-bg-color)'
      }
    },

    setBackBtn() {
      if (this.$route.name === 'home') {
        tg.BackButton.isVisible = false
      } else {
        tg.BackButton.isVisible = true
        tg.BackButton.onClick(() => {
          this.$router.go(-1)
        })
      }
    },

    async getFooterInfo() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/contacts')
          .then(res => {
            this.footerDetails.title = res.data.title
            this.footerDetails.phone = res.data.phone
            this.footerDetails.phone2 = res.data.phone2
          })
          .catch(error => {
            console.error(error)
          })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //background-color: (--tg-theme-secondary-bg-color);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
