<template>

  <div>
    <h1 class="title main__title">Aвтокредит</h1>

    <div class="main">
      <div class="container">

        <div class="main__cards">
          <router-link
            v-for="(card, index) in loanCards"
            :key="index"
            class="main__card"
            :class="card.className"
            :to="card.routeTo"
          >
            <img :src="card.image" alt="">
            <span>{{ card.text }}</span>
          </router-link>
        </div>

      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'AppMain',
  components: {},
  data() {
    return {
      loanCards: [
        {
          text: 'Условия кредита',
          image: require('@/assets/img/form-for-loan-bg.png'),
          className: 'main__card-1',
          routeTo: '/loan'
        },
        {
          text: 'Калькулятор кредита',
          image: require('@/assets/img/main-calculator-bg.png'),
          className: 'main__card-2',
          routeTo: '/calculator'
        },
        {
          text: 'Подать заявку на кредит',
          image: require('@/assets/img/main-terms-bg.png'),
          className: 'main__card-3',
          routeTo: '/form'
        },
        {
          text: 'Почему это надежно?',
          image: require('@/assets/img/main-lines.png'),
          className: 'main__card-4',
          routeTo: '/reliability'
        },
        {
          text: 'Почему это выгодно?',
          image: require('@/assets/img/main-lines.png'),
          className: 'main__card-5',
          routeTo: '/profitability'
        },
      ]
    }
  },

  // created() {
  //   console.log(window.Telegram.WebApp)
  //   window.Telegram.WebApp.setBackgroundColor(() => '#FACC15')
  // }
}
</script>

<style lang="scss" scoped>

.main__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 133%;
  color: var(--tg-theme-text-color);
  text-transform: uppercase;
}

.main {
  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    //grid-template-rows: 160px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  &__card {
    position: relative;
    padding: 12px;
    background-color: #FACC15;
    border-radius: .5rem;
    text-decoration: none;

    & span {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #000000;
      display: block;
      text-align: left;
      z-index: 10;
      width: 90%;
    }

    & img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      border-radius: .5rem;
      object-position: bottom;
    }
  }

  &__card-1 {
    grid-area: 1 / 1 / 3 / 2;
    height: 160px;
  }

  &__card-2 {
    grid-area: 1 / 2 / 3 / 3;
    height: 160px;
  }

  &__card-3 {
    grid-area: 3 / 1 / 5 / 2;
    height: 160px;
  }

  &__card-4 {
    grid-area: 3 / 2 / 4 / 3;
  }

  &__card-5 {
    grid-area: 4 / 2 / 5 / 3;
  }
}

</style>
