import Vue from 'vue'
import VueRouter from 'vue-router'
import AppMain from "@/views/main/AppMain";
import AppLoan from "@/views/loan/AppLoan";
import AppCalculator from "@/views/calculator/AppCalculator";
import AppForm from "@/views/form/AppForm";
import AppAboutProfitable from "@/views/about/AppAboutProfitable";
import AppAboutReliaby from "@/views/about/AppAboutReliaby";
import AppFormSuccess from "@/views/form/AppFormSuccess";
import AppCalculation from "@/views/calculator/AppCalculation";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: AppMain
  },
  {
    path: '/loan',
    name: 'loan',
    component: AppLoan
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: AppCalculator
  },
  {
    path: '/form',
    name: 'form',
    component: AppForm,
  },
  {
    path: '/form-success',
    name: 'form-success',
    component: AppFormSuccess,
  },
  {
    path: '/reliability',
    name: 'reliability',
    component: AppAboutReliaby
  },
  {
    path: '/profitability',
    name: 'profitability',
    component: AppAboutProfitable
  },
  {
    path: '/calculation',
    name: 'calculation',
    component: AppCalculation
  },
  // {
  //   path: '/about',
  //   name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
