import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import axios from 'axios'
import 'vue-select/dist/vue-select.css';
import {BootstrapVue, IconsPlugin, LayoutPlugin} from "bootstrap-vue";
import "@/assets/main.scss";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export const tg = window.Telegram.WebApp
console.log(tg)
Vue.prototype.$http = axios;


// let tg = window.Telegram.WebApp
// console.log(tg)

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
