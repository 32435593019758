<template>
  <div>
    <h2 class="title loan__title">Условия кредита</h2>

    <div class="loan">
      <div class="container">

        <div class="loan__info">
          <div
            v-for="(item, index) in loanDetails"
            :key="index"
          >
            <span
              v-html="item"
            >
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoan",
  data() {
    return {
      loanDetails: [
      ]
    }
  },

  async mounted() {
    await this.getLoanDetails()
  },

  methods: {
    async getLoanDetails() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/loanTerms')
        .then(res => {
          console.log(res)
          this.loanDetails.push(res.data['loan_period'])
          this.loanDetails.push(res.data['interest_rate'])
          this.loanDetails.push(res.data['initial_payment'])
          this.loanDetails.push(res.data['max_loan_amount'])
          this.loanDetails.push(res.data['grace_period'])
          this.loanDetails.push(res.data['payment_methods'])
          console.log(this.loanDetails[0])
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.loan__title {
  color: var(--tg-theme-text-color);
}

.loan {
  background-color: #FACC15;
  padding: 4px 0 12px;

  ::v-deep &__info {
    strong {
      margin-top: 12px;
      display: block;
      font-weight: 600;
    }

    p {
      margin-bottom: 4px;
    }

    & span {
      display: block;
      text-align: left;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #000000;
    }

    & .text-bold {
      font-weight: 600;
      padding-top: 1rem;
    }

    & .text-regular {
      font-weight: 400;
      width: 90%;
    }
  }

}

</style>
