<template>
  <div>
    <h2 class="title">{{ reliabilityTitle }}</h2>
    <div class="reliability">
      <div class="container">
        <p
          v-for="(item, index) in reliabilityAnswers"
          :key="index"
        >
          {{ item['answer'] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAboutReliaby",
  data() {
    return {
      reliabilityTitle: '',
      reliabilityAnswers: []
    }
  },

  async created() {
    await this.getReliabilityDetails()
  },

  methods: {
    async getReliabilityDetails() {
      await this.$http.get(process.env.VUE_APP_URL + 'api/questions/1')
        .then(res => {
          console.log(res)
          this.reliabilityTitle = res.data['question']
          this.reliabilityAnswers = res.data['answers']
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.reliability {
  padding: 16px 0 8px;
  background-color: #FACC15;

  & p {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
  }
}

</style>
